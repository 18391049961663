<template>
  <div>
    <div class="row flex center">
      <div class="col-lg-12 col-xl-12">
        <KTPortlet title="Image Background Remover" data-app="keywords">
          <template v-slot:toolbar>
            <template v-if="imgz.length > 1">
              <v-btn
                color="danger"
                @click="imgz = []"
                style="margin-right: 10px;"
                >Clear results</v-btn
              >
            </template>
            <template v-if="imgz.length > 1 && bulkSupported">
              <v-btn
                color="success"
                @click="bulkDownload()"
                style="margin-right: 10px;"
                >Bulk download images</v-btn
              >
            </template>
            <template v-if="credits !== null">
              You have {{ credits }} credits.
            </template>
          </template>

          <template slot="body">
            <div class="row flex left">
              <div class="col-lg-6 col-xl-6">
                <vue-dropzone
                  ref="myVueDropzone"
                  id="dropzone"
                  :options="dropzoneOptions"
                  @xvdropzone-file-added="vdropzonefileadded"
                  @xvdropzone-thumbnail="vdropzonethumbnail"
                  @vdropzone-success="vdropzonesuccess"
                  @vdropzone-error="vdropzoneerror"
                ></vue-dropzone>
              </div>
              <div class="col-lg-6 col-xl-6">
                <div class="row flex left start">
                  <div
                    class="col-lg-6 col-xl-6"
                    v-for="(img, index) in imgz"
                    v-bind:key="index"
                  >
                    <img
                      :src="img.base64"
                      style="max-width: 100%; max-height: 100%;"
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<style scoped>
@media (min-width: 1024px) {
  #dropzone {
    min-height: 400px;
    line-height: 400px;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import KTPortlet from "@/views/partials/content/Portlet.vue";
//import Loader from "@/views/partials/content/Loader.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import TLib from "./lib";
import vue2Dropzone from "../../../common/vue2-dropzone/components/vue-dropzone.vue";
import { saveAs } from "file-saver";
//import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  components: {
    KTPortlet,
    vueDropzone: vue2Dropzone
    //Loader,
  },
  watch: {},
  data() {
    return {
      //imgsrc: [],
      imgz: [],
      credits: null
    };
  },
  methods: {
    async bulkDownload() {
      for (let file of this.imgz) {
        let newNameAsPng = `${file.file.name}`.split(".");
        if (newNameAsPng.length < 2) {
          newNameAsPng.push("png");
        } else {
          newNameAsPng[newNameAsPng.length - 1] = "png";
        }
        saveAs(file.blob, newNameAsPng.join("."));
        await new Promise(r => setTimeout(r, 500));
      }
    },
    //vdropzonefileadded(file) {
    //  //console.log("vdropzonefileadded", file);
    //},
    //vdropzonethumbnail(file, dataUrl) {
    //  //console.log("vdropzonethumbnail", file, dataUrl);
    //},
    vdropzonesuccess(file, response) {
      const self = this;
      this.$refs.myVueDropzone.removeFile(file);
      //console.log("vdropzonesuccess", file, response);
      //console.log(response);
      /*console.log(
        `data:${response.contentType};base64,${btoa(
          unescape(encodeURIComponent(response.response))
        )}`
      );
      self.imgsrc = `data:${response.contentType};base64,${btoa(
        unescape(encodeURIComponent(response.response))
      )}`;*/
      const blob = new Blob([response.response], {
        type: response.contentType
      });
      function blobToBase64(blob) {
        return new Promise(resolve => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });
      }
      blobToBase64(blob)
        .then(x => {
          //self.imgsrc.push(x);
          self.imgz.push({
            file: file,
            base64: x,
            blob: blob
          });
          this.$root.$bvToast.toast("Image background removed successfully", {
            title: `Converted`,
            variant: "success",
            toaster: "b-toaster-bottom-right",
            solid: true
          });
        })
        .catch(x => {
          self.vdropzoneerror(file, x.message || x);
        });
    },
    vdropzoneerror(file, message, xhr) {
      this.$log.error("vdropzoneerror", file, message, xhr);
      this.$root.$bvToast.toast(message, {
        title: `Error`,
        variant: "danger",
        toaster: "b-toaster-bottom-right",
        solid: true
      });
    },
    updateCredits() {
      const self = this;
      TLib.getCredits(this, this.$root.clientId, this.$route.params.serviceId)
        .then(x => {
          self.credits = x.credits;
        })
        .catch(self.$log.error);
    }
  },
  computed: {
    bulkSupported() {
      try {
        return !!new Blob();
      } catch (e) {
        return false;
      }
    },
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },

    dropzoneOptions() {
      return TLib.removeBgGetDropzoneOptions(
        this,
        {
          thumbnailWidth: 150,
          maxFilesize: 11
          //disablePreviews: true
          //binaryBody: true,
          //acceptedFiles: ["image/jpg", "image/png"]
        },
        this.$root.clientId,
        this.$route.params.serviceId
      );
    }
  },
  beforeDestroy() {
    this.$eventBus.off(`switch-service-selected`);
    this.$eventBus.off(`ws-crm-event`);
    //this.$eventBus.off(`cache-crm:services`);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Tools" },
      { title: "Image Background Remover" }
    ]);

    let self = this;

    self.updateCredits();
    self.$eventBus.on(`switch-service-selected`, () => self.updateCredits());
    self.$eventBus.on(`ws-crm-event`, x => {
      if (x.action !== "service-edit") return;
      if (`${x.data.entityId}` !== self.$route.params.serviceId) return;

      self.updateCredits();
    });
    //self.$eventBus.on(`cache-crm:services`, () => self.resync(false));
    //self.resync();
  }
};
</script>
