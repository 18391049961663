export default {
  removeBgGetDropzoneOptions(self, opts, clientId, serviceId) {
    opts = opts || {};

    opts.url = self.$api.getBaseURL('tool-removebg') + '/removebg?size=full&type=auto&typeLevel=latest&format=auto';
    opts.headers = self.$api.getAxiosHeaders({}, true, false, false);
    opts.headers['Authorization'] = `${opts.headers["tenant-id"]}|${opts.headers["app-id"]}|${opts.headers['Authorization'].split(' ')[1]}|${clientId}|${serviceId}`

    return opts;
  },
  getCredits(self, clientId, serviceId) {
    return new Promise((resolve, reject) => {
      let headers = self.$api.getAxiosHeaders({}, true, false, false);
      headers['Authorization'] = `${headers["tenant-id"]}|${headers["app-id"]}|${headers['Authorization'].split(' ')[1]}|${clientId}|${serviceId}`;
      self.$api.get('tool-removebg', 'removebg', {
          overideheaders: headers
        })
        .then(x => resolve(x.data))
        .catch(reject);
    });
  }
  /*setAdBudTerm: (self, serviceId, term, wanted) =>
    new Promise((resolve, reject) => {
      self.$api.update2(
          `tool-removebg`,
          `${self.$root.clientId}/${serviceId}/adbud/terms`,
          undefined,
          {
            term,
            wanted
          }
        )
        .then(x => resolve(x.data))
        .catch(reject);
    }),*/
};